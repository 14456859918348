@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 255, 255, 255;
    --background-rgb: 10, 10, 10;
}

body {
    color: rgb(var(--foreground-rgb));
    background: rgb(var(--background-rgb));
}

select {
    background: rgb(var(--background-rgb));
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

.animate-in {
    animation: fadeIn .5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
